<template>
  <div>
    <order-component :status="$options.STATUS">
      <template v-slot:empty>
        <div class="my-5 text-center">
          <h3>{{ $t('empty orders') }}</h3>
        </div>
      </template>
    </order-component>
    <div>
      <static-block-component page="profile" position="top" />
      <app-link to="/schedule" class="w-100 btn btn-block linked-button"
        >{{ $t('Watch') }} upcoming events</app-link
      >
    </div>
  </div>
</template>

<script>
import OrderComponent from './OrdersComponent';
import StaticBlockComponent from '../StaticBlockComponent';
import AppLink from '../base-components/AppLink';
import { ORDER_STATUS } from '../../const';
export default {
  name: 'ActiveOrders',
  components: { AppLink, StaticBlockComponent, OrderComponent },
  STATUS: [ORDER_STATUS.paid],
};
</script>

<style scoped>
.linked-button {
  background: var(--settings-buy-button-background);
  color: var(--settings-buy-button-text-color);
  font-weight: bold;
}
</style>
