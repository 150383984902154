<template>
    <div class="static-block-placement"  v-if="content && content.length">
        <div class="static-block"
             v-for="(text, index) in content" v-html="text" :key="index"></div>
    </div>
</template>


<script>
    import {mapState} from "vuex";
    import {isArray} from "lodash"

    export default {
        name: "StaticBlockComponent",
        data() {
            return {
                content: []
            }

        },
        props: {
            page: null,
            position: null
        },
        computed: {
            ...mapState({
                static_blocks: state => state.channel.settings.static_blocks
            }),
        },
        mounted: function () {
            if (this.static_blocks && this.static_blocks[this.page] && this.static_blocks[this.page][this.position]) {
                if(isArray(this.static_blocks[this.page][this.position])) {
                    this.static_blocks[this.page][this.position].forEach((data) => {
                        this.content.push(this.convertEditorJsonToHtml(data));
                    })
                } else {
                    this.content.push(this.convertEditorJsonToHtml(this.static_blocks[this.page][this.position]))
                }

            }
        }
    }
</script>

<style lang="scss">
    .static-block{
        max-width: 100vw;
        img{
            width: 100%;
        }
    }
</style>